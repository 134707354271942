import { useEffect, useState } from "react";
import axios from "../api/axios";

export const useFetchCongestionData = (cameraData, updateMarkerIcon, updatePolygonColor) => {
  const [intervals, setIntervals] = useState({});

  const fetchCongestionLevel = async (cam_id) => {
    try {
      const response = await axios.get(`/backend_data/${cam_id}`);
      console.log(`Fetched congestion data for camera ${cam_id}:`, response.data.tcongestion);
      return response.data.tcongestion || null;
    } catch (error) {
      if (error.response?.status === 404) {
        console.warn(`Camera ${cam_id} not found. Stopping fetch.`);
        clearIntervalForCamera(cam_id);
      }
      console.error(`Error fetching congestion data for camera ${cam_id}:`, error);
      return null;
    }
  };

  const clearIntervalForCamera = (cam_id) => {
    if (intervals[cam_id]) {
      clearInterval(intervals[cam_id]);
      setIntervals((prev) => {
        const updated = { ...prev };
        delete updated[cam_id];
        return updated;
      });
    }
  };

  const continuouslyFetchCongestionData = (camera, marker, polygon) => {
    if (!camera || !camera.cam_id) return;
  
    clearIntervalForCamera(camera.cam_id);
  
    const intervalId = setInterval(async () => {
      const isCameraStillPresent = cameraData.some(
        (cam) => cam.cam_id === camera.cam_id
      );
  
      if (!isCameraStillPresent) {
        clearIntervalForCamera(camera.cam_id);
        return;
      }
  
      try {
        const tcongestion = await fetchCongestionLevel(camera.cam_id);
  
        if (tcongestion !== null) {
          let updatedTcongestion;
  
          if (camera.two_lanes == 1 || camera.two_lanes === '1') {
            // For two-lane cameras, ensure both lane1 and lane2 are present
            updatedTcongestion = {
              lane1: tcongestion.lane1,
              lane2: tcongestion.lane2,
            };
          } else {
            // For single-lane cameras, handle both object and string formats
            updatedTcongestion = typeof tcongestion === 'object'
              ? { lane1: tcongestion.lane1 }
              : { lane1: tcongestion };
          }
  
          console.log(`Updated congestion data for camera ${camera.cam_id}:`, updatedTcongestion);
  
          updateMarkerIcon(marker, updatedTcongestion, camera);
          updatePolygonColor(polygon, updatedTcongestion, camera);
        }
      } catch (error) {
        console.error(`Error processing congestion data for camera ${camera.cam_id}:`, error);
        clearIntervalForCamera(camera.cam_id);
      }
    }, 3000);
  
    setIntervals((prev) => ({ ...prev, [camera.cam_id]: intervalId }));
  };

  useEffect(() => {
    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, [intervals]);

  return { continuouslyFetchCongestionData, clearIntervalForCamera };
};