import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/style.css";
import drainage from "../images/drainage.png";
import entrance from "../images/Entrance.png";
import exit from "../images/Exit.png";
import noenter from "../images/noentry.png";
import building from "../images/building.png";
import roadcons from "../images/roadcons.png";
import fire from "../images/fire.png";
import vaccident from "../images/vaccident.png";
import flood from "../images/flood.png";
import landslide from "../images/landslide.png";
import { Modal, Button, Form } from "react-bootstrap";
import axiosphp from "../api/axiosphp";
import { ToastContainer, toast } from "react-toastify";
import axios from "../api/axios";
import { useFetchCongestionData } from "../hooks/useFetchCongestionData";

const markerIcons = {
  "Entrance Only": entrance,
  "Exit Only": exit,
  "No Entry": noenter,
  "Drainage": drainage,
  "Building": building,
  "Road": roadcons,
  "Fire": fire,
  "Flood": flood,
  "Landslide": landslide,
  "Vehicular Accidents": vaccident,
};

const tcongestionColor = {
  Gridlock: "#AB0003",
  Heavy: "#FF1519",
  Moderate: "#FFA800",
  Light: "#FFE500",
  Freeflow: "#05FF00",
  null: "white",
};

function MapComponent({ initialDrawings, selectedCongestion }) {
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [cameraPosition, setCameraPosition] = useState(null);
  const [cameraName, setCameraName] = useState("");
  const [cameraLocation, setCameraLocation] = useState("");
  const [cameraUrl, setCameraUrl] = useState("");
  const [cameraData, setCameraData] = useState([]);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [isTwoLanes, setIsTwoLanes] = useState(false);
  const [editedCamera, setEditedCamera] = useState({ cam_name: '', cam_location: '', cam_url: '' });
  const mapModalRef = useRef(null);
  const drawingManagerRef = useRef(null);
  const [streetLocation, setStreetLocation] = useState(null);
  const [streetPolygon, setStreetPolygon] = useState(null);
  const lanePolygonsRef = useRef({});
  const markerCongestionMap = useRef(new Map());
  const polygonCongestionMap = useRef(new Map());

  const filterByCongestion = () => {
    const zoom = mapRef.current?.getZoom?.() || 13;

    markersRef.current.forEach(marker => {
      const { lane1, lane2 } = markerCongestionMap.current.get(marker) || {};
      let match = false;
      if (!selectedCongestion) {
        match = true; // Show all if no filter
      } else if (lane2) {
        // Two-lane
        match = (lane1 === selectedCongestion || lane2 === selectedCongestion);
      } else {
        // Single-lane
        match = (lane1 === selectedCongestion);
      }
      marker.setVisible(match && zoom < 18);
    });

    polygonCongestionMap.current.forEach((congestionLevel, polygon) => {
      let match = false;
      if (!selectedCongestion) {
        match = true;
      } else {
        match = (congestionLevel === selectedCongestion);
      }
      polygon.setVisible(match);
    });
  };

  const updateMarkerIcon = (marker, tcongestion, camera) => {
    const isTwoLanes = camera.two_lanes === 1 || camera.two_lanes === '1';
    console.log(`Updating marker for camera ${camera.cam_id}, two lanes: ${isTwoLanes}`);

    const normalizeCongestionLevel = (level) => {
      if (!level) return "Freeflow";
      return level.charAt(0).toUpperCase() + level.slice(1).toLowerCase();
    };

    if (isTwoLanes) {
      const lane1Congestion = normalizeCongestionLevel(tcongestion?.lane1) || "Freeflow";
      const lane2Congestion = normalizeCongestionLevel(tcongestion?.lane2) || "Freeflow";
      console.log(`Lane1: ${lane1Congestion}, Lane2: ${lane2Congestion}`);

      const color1 = tcongestionColor[lane1Congestion] || "black";
      const color2 = tcongestionColor[lane2Congestion] || "black";

      const canvas = document.createElement('canvas');
      canvas.width = 30;
      canvas.height = 30;
      const context = canvas.getContext('2d');

      // Draw left half (Lane 1)
      context.beginPath();
      context.arc(15, 15, 12, 0.5 * Math.PI, 1.5 * Math.PI);
      context.closePath();
      context.fillStyle = color1;
      context.fill();

      // Draw right half (Lane 2)
      context.beginPath();
      context.arc(15, 15, 12, 1.5 * Math.PI, 0.5 * Math.PI);
      context.closePath();
      context.fillStyle = color2;
      context.fill();

      // Draw the border
      context.beginPath();
      context.arc(15, 15, 12, 0, 2 * Math.PI);
      context.closePath();
      context.lineWidth = 3;
      context.strokeStyle = '#000';
      context.stroke();

      const iconUrl = canvas.toDataURL();
      marker.setIcon({
        url: iconUrl,
        scaledSize: new window.google.maps.Size(16, 16),
      });

      // Store congestion info for filtering
      markerCongestionMap.current.set(marker, { lane1: lane1Congestion, lane2: lane2Congestion });
    } else {
      const congestionLevel = normalizeCongestionLevel(tcongestion?.lane1) || "Freeflow";
      const fillColor = tcongestionColor[congestionLevel] || "black";
      console.log(`Single lane congestionLevel: ${congestionLevel}, fillColor: ${fillColor}`);

      const customMarker = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: fillColor,
        fillOpacity: 1,
        scale: 6.5,
        strokeColor: "#000",
        strokeWeight: 1.6,
      };
      marker.setIcon(customMarker);

      // Store congestion info for filtering
      markerCongestionMap.current.set(marker, { lane1: congestionLevel });
    }

    // Apply filtering after updating the icon
    filterByCongestion();
  };

  const updatePolygonColor = (polygon, tcongestion, camera) => {
    const isTwoLanes = camera.two_lanes === 1 || camera.two_lanes === '1';
    console.log(`Updating polygon for camera ${camera.cam_id}, two lanes: ${isTwoLanes}`);

    const normalizeCongestionLevel = (level) => {
      if (!level) return "Freeflow";
      return level.charAt(0).toUpperCase() + level.slice(1).toLowerCase();
    };

    if (isTwoLanes) {
      const lane1Congestion = normalizeCongestionLevel(tcongestion?.lane1) || "Freeflow";
      const lane2Congestion = normalizeCongestionLevel(tcongestion?.lane2) || "Freeflow";
      console.log(`Lane1 Congestion: ${lane1Congestion}, Lane2 Congestion: ${lane2Congestion}`);

      const color1 = tcongestionColor[lane1Congestion] || "black";
      const color2 = tcongestionColor[lane2Congestion] || "black";
      console.log(`Lane1 Color: ${color1}, Lane2 Color: ${color2}`);

      if (lanePolygonsRef.current[camera.cam_id]) {
        const { lane1, lane2 } = lanePolygonsRef.current[camera.cam_id];
        lane1.setOptions({
          fillColor: color1,
        });
        lane2.setOptions({
          fillColor: color2,
        });
        console.log(`Updated colors for existing two polygons of camera ${camera.cam_id}`);
      } else {
        if (polygon.lane === 'lane1' || polygon.lane === 'lane2') {
          const fillColor = polygon.lane === 'lane1' ? color1 : color2;
          polygon.setOptions({
            fillColor: fillColor,
          });
          console.log(`Updated ${polygon.lane} polygon for camera ${camera.cam_id}`);
        } else {
          const streetCoords = JSON.parse(camera.streetlocation);
          if (Array.isArray(streetCoords) && streetCoords.length === 2) {
            const map = polygon.getMap();

            const lane1Path = streetCoords[0];
            const lane2Path = streetCoords[1];

            const lane1Polygon = new window.google.maps.Polygon({
              paths: lane1Path,
              map: map,
              strokeColor: "#000",
              strokeWeight: 0,
              fillColor: color1,
              fillOpacity: 0.8,
            });
            lane1Polygon.lane = 'lane1';

            const lane2Polygon = new window.google.maps.Polygon({
              paths: lane2Path,
              map: map,
              strokeColor: "#000",
              strokeWeight: 0,
              fillColor: color2,
              fillOpacity: 0.8,
            });
            lane2Polygon.lane = 'lane2';

            lane1Polygon.addListener("click", () => {
              setSelectedCamera(camera);
              setShowCameraModal(true);
            });

            lane2Polygon.addListener("click", () => {
              setSelectedCamera(camera);
              setShowCameraModal(true);
            });

            lanePolygonsRef.current[camera.cam_id] = {
              lane1: lane1Polygon,
              lane2: lane2Polygon,
            };

            console.log(`Assigned existing split polygons for camera ${camera.cam_id}`);

            // Store each lane's congestion separately
            polygonCongestionMap.current.set(lane1Polygon, lane1Congestion);
            polygonCongestionMap.current.set(lane2Polygon, lane2Congestion);

            // Remove the original polygon
            polygon.setMap(null);
          } else {
            console.error(`Unexpected streetlocation format for camera ${camera.cam_id}`, streetCoords);
            const fillColor = color1;
            polygon.setOptions({
              fillColor: fillColor,
              fillOpacity: 0.8,
              strokeColor: '#000',
              strokeWeight: 0,
            });
            console.log(`Applied fallback color to single polygon for camera ${camera.cam_id}`);

            // Store congestion info
            polygonCongestionMap.current.set(polygon, lane1Congestion);
          }
        }
      }
    } else {
      const congestionLevel = normalizeCongestionLevel(tcongestion?.lane1) || "Freeflow";
      const fillColor = tcongestionColor[congestionLevel] || "black";
      console.log(`Single lane congestionLevel: ${congestionLevel}, fillColor: ${fillColor}`);

      polygon.setOptions({
        fillColor: fillColor,
        fillOpacity: 0.80,
        strokeColor: '#000',
        strokeWeight: 0,
      });
      console.log(`Updated single polygon for camera ${camera.cam_id}`);

      // Store congestion info for filtering
      polygonCongestionMap.current.set(polygon, congestionLevel);
    }

    // Apply filtering after updating the polygon
    filterByCongestion();
  };

  const { continuouslyFetchCongestionData, clearIntervalForCamera } = useFetchCongestionData(
    cameraData,
    updateMarkerIcon,
    updatePolygonColor // Pass updatePolygonColor here
  );

  useEffect(() => {
    if (mapRef.current) {
      filterByCongestion();
    }
  }, [selectedCongestion]);

  const fetchCameraData = () => {
    axiosphp.get("/fetch_traffic_cameras.php")
      .then(response => {
        setCameraData(response.data);
      })
      .catch(error => {
        console.error("Error fetching camera data:", error);
      });
  };

  useEffect(() => {
    fetchCameraData();
  }, []);

  const markersRef = useRef([]);
  
  const loadMap = () => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps JavaScript API not loaded.");
      return;
    }

    const map = new window.google.maps.Map(document.getElementById("mapstyle"), {
      center: { lat: 11.2416, lng: 125.0028 },
      zoom: 13,
    });
    mapRef.current = map;

    markersRef.current = [];
    lanePolygonsRef.current = {};

    map.addListener("zoom_changed", () => {
      const zoom = map.getZoom();
      markersRef.current.forEach(marker => {
        const { lane1, lane2 } = markerCongestionMap.current.get(marker) || {};
        let match = false;
        if (!selectedCongestion) {
          match = true;
        } else if (lane2) {
          match = (lane1 === selectedCongestion || lane2 === selectedCongestion);
        } else {
          match = (lane1 === selectedCongestion);
        }
        marker.setVisible(match && zoom < 18);
      });

      polygonCongestionMap.current.forEach((congestionLevel, polygon) => {
        let match = false;
        if (!selectedCongestion) {
          match = true;
        } else {
          match = (congestionLevel === selectedCongestion);
        }
        polygon.setVisible(match);
      });
    });

    cameraData.forEach(camera => {
      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(camera.cam_latitude), lng: parseFloat(camera.cam_longitude) },
        map: map,
        title: camera.cam_name,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "white",
          fillOpacity: 1,
          scale: 6.5,
          strokeColor: "#000",
          strokeWeight: 1.6,
        },
        visible: map.getZoom() < 16,
      });

      markersRef.current.push(marker);

      let polygon = null;
      if (camera.streetlocation) {
        const streetCoords = JSON.parse(camera.streetlocation);
        polygon = new window.google.maps.Polygon({
          paths: streetCoords,
          map: map,
          strokeColor: "#000",
          strokeWeight: 1.6,
          fillOpacity: 0.35,
          geodesic: true,
        });
      }

      continuouslyFetchCongestionData(camera, marker, polygon);
      if (polygon) {
        polygon.addListener("click", () => {
          setSelectedCamera(camera);
          setShowCameraModal(true);
        });
      }

      marker.addListener("click", () => {
        setSelectedCamera(camera);
        setShowCameraModal(true);
      });
    });

    if (initialDrawings && initialDrawings.length > 0) {
      initialDrawings.forEach((data) => {
        let overlay;
        switch (data.type) {
          case window.google.maps.drawing.OverlayType.MARKER:
            overlay = new window.google.maps.Marker({
              position: data.position,
              map: map,
              icon: {
                url: markerIcons[data.name] || null,
                scaledSize: new window.google.maps.Size(50, 30),
              },
              label: data.name,
            });
            break;
          case window.google.maps.drawing.OverlayType.CIRCLE:
            overlay = new window.google.maps.Circle({
              center: data.position,
              radius: data.size,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYGON:
            overlay = new window.google.maps.Polygon({
              paths: data.position,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYLINE:
            overlay = new window.google.maps.Polyline({
              path: data.position,
              map: map,
              strokeColor: data.color,
              strokeWeight: 4,
            });
            break;
          case window.google.maps.drawing.OverlayType.RECTANGLE:
            overlay = new window.google.maps.Rectangle({
              bounds: data.position,
              map: map,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          default:
            console.warn(`Unsupported overlay type: ${data.type}`);
            return;
        }
      });
    }

    map.addListener("dblclick", (event) => {
      setCameraPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      setShowModal(true);
    });

    // Initial filter application
    filterByCongestion();
  };

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.drawing) {
      console.error("Google Maps JavaScript API or Drawing library not loaded.");
      return;
    }

    loadMap();
  }, [cameraData, initialDrawings, navigate, selectedCongestion]);
  

  useEffect(() => {
    if (showModal) {
      initializeModalMap();
    }
  }, [isTwoLanes]);

  const initializeModalMap = () => {
    if (!window.google || !window.google.maps) return;

    const modalMap = new window.google.maps.Map(document.getElementById("modal-map"), {
      center: cameraPosition || { lat: 11.2416, lng: 125.0028 },
      zoom: 16,
      disableDefaultUI: false,
      styles: [
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        { elementType: "labels", stylers: [{ visibility: "off" }] },
      ],
    });
    mapModalRef.current = modalMap;

    // Add a circle to show the camera position
    if (cameraPosition) {
      new window.google.maps.Circle({
        map: modalMap,
        center: cameraPosition,
        radius: 0.7,
        strokeColor: "black",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#5f5f5f",
        fillOpacity: 0.35,
      });
    }

    if (isTwoLanes) {
      // Define default coordinates for the two connected polygons
      const midLng = cameraPosition.lng; // Shared longitude for connected middle

      const lane1Coords = [
        { lat: cameraPosition.lat + 0.0001, lng: cameraPosition.lng - 0.0002 },
        { lat: cameraPosition.lat + 0.0001, lng: midLng },
        { lat: cameraPosition.lat - 0.0001, lng: midLng },
        { lat: cameraPosition.lat - 0.0001, lng: cameraPosition.lng - 0.0002 },
      ];

      const lane2Coords = [
        { lat: cameraPosition.lat + 0.0001, lng: midLng },
        { lat: cameraPosition.lat + 0.0001, lng: cameraPosition.lng + 0.0002 },
        { lat: cameraPosition.lat - 0.0001, lng: cameraPosition.lng + 0.0002 },
        { lat: cameraPosition.lat - 0.0001, lng: midLng },
      ];

      // Create editable polygons for each lane
      const lane1Polygon = new window.google.maps.Polygon({
        paths: lane1Coords,
        map: modalMap,
        strokeColor: "black",
        fillColor: "blue",
        fillOpacity: 0.3,
        strokeWeight: 2,
        editable: true,
        geodesic: true, // Added geodesic property
      });

      const lane2Polygon = new window.google.maps.Polygon({
        paths: lane2Coords,
        map: modalMap,
        strokeColor: "black",
        fillColor: "red",
        fillOpacity: 0.3,
        strokeWeight: 2,
        editable: true,
        geodesic: true, // Added geodesic property
      });

      setStreetPolygon([lane1Polygon, lane2Polygon]);

      let isSynchronizing = false; // Flag to prevent recursive calls

      // Synchronize shared edges to keep polygons connected
      const synchronizePolygons = (changedPolygon) => {
        if (isSynchronizing) return; // Prevent recursive calls
        isSynchronizing = true;

        const lane1Path = lane1Polygon.getPath();
        const lane2Path = lane2Polygon.getPath();

        if (changedPolygon === lane1Polygon) {
          // Sync lane2's shared points with lane1
          const sharedPoint1 = lane1Path.getAt(1);
          const sharedPoint2 = lane1Path.getAt(2);
          lane2Path.setAt(0, sharedPoint1);
          lane2Path.setAt(3, sharedPoint2);
        } else {
          // Sync lane1's shared points with lane2
          const sharedPoint1 = lane2Path.getAt(0);
          const sharedPoint2 = lane2Path.getAt(3);
          lane1Path.setAt(1, sharedPoint1);
          lane1Path.setAt(2, sharedPoint2);
        }

        // Update streetLocation state
        const updatedLane1Path = lane1Path.getArray().map((point) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));
        const updatedLane2Path = lane2Path.getArray().map((point) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));
        setStreetLocation([updatedLane1Path, updatedLane2Path]);

        isSynchronizing = false; // Reset the flag
      };

      // Add listeners to sync polygons when paths change
      [lane1Polygon, lane2Polygon].forEach((polygon) => {
        const path = polygon.getPath();
        path.addListener("set_at", () => synchronizePolygons(polygon));
        path.addListener("insert_at", () => synchronizePolygons(polygon));
        path.addListener("remove_at", () => synchronizePolygons(polygon));
      });

      // Initialize coordinates
      synchronizePolygons(lane1Polygon);
    } else {
      // Single lane drawing manager
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setMap(null);
      }

      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          editable: true,
          draggable: false,
          strokeColor: "black",
          fillColor: "#5f5f5f",
          fillOpacity: 0.5,
          strokeWeight: 2,
          geodesic: true, // Added geodesic property
        },
      });

      drawingManager.setMap(modalMap);
      drawingManagerRef.current = drawingManager;

      window.google.maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
        if (streetPolygon) {
          if (Array.isArray(streetPolygon)) {
            streetPolygon.forEach((poly) => poly.setMap(null));
          } else {
            streetPolygon.setMap(null);
          }
        }
      
        const polygon = event.overlay;
        setStreetPolygon(polygon);
      
        const path = polygon.getPath();
        const coordinates = [];
        for (let i = 0; i < path.getLength(); i++) {
          const point = path.getAt(i);
          coordinates.push({ lat: point.lat(), lng: point.lng() });
        }
        setStreetLocation(coordinates);
      
        function updateCoordinates() {
          const updatedCoordinates = [];
          for (let i = 0; i < path.getLength(); i++) {
            const point = path.getAt(i);
            updatedCoordinates.push({ lat: point.lat(), lng: point.lng() });
          }
          setStreetLocation(updatedCoordinates);
        }
      
        path.addListener("set_at", updateCoordinates);
        path.addListener("insert_at", updateCoordinates);
        path.addListener("remove_at", updateCoordinates);
      });
    }
  };

  const handleAddCamera = () => {
    if (!cameraName || !cameraLocation || !cameraUrl || !cameraPosition) {
      alert("Please fill out all fields");
      return;
    }
    setShowConfirmModal(true);
  };


  const confirmAddCamera = () => {
    const newCamera = {
      camname: cameraName,
      camlocation: cameraLocation,
      camurl: cameraUrl,
      lat: cameraPosition.lat.toFixed(6),
      lng: cameraPosition.lng.toFixed(6),
      twolanes: isTwoLanes ? 1 : 0,
      streetlocation: JSON.stringify(streetLocation),
      lane_position: JSON.stringify([
        { x: 30, y: 70 },
        { x: 1170, y: 70 },
        { x: 1170, y: 570 },
        { x: 30, y: 570 }
      ]),
      lane_position_two: isTwoLanes
        ? JSON.stringify([
          { x: 488, y: 182 },
          { x: 836, y: 179 },
          { x: 864, y: 450 },
          { x: 452, y: 453 }
        ])
        : null, 
    };

    axiosphp
      .post("/add_traffic_camera.php", newCamera)
      .then((response) => {
        if (response.data.success) {
          toast.success("Camera added successfully", { autoClose: 3000 });
          setCameraName("");
          setCameraLocation("");
          setCameraUrl("");
          fetchCameraData(); 
          axios.post('/update_camera_data') 
            .then((response) => {
              if (response.data.success) {
                console.log("Camera data updated successfully.");
              } else {
                console.error("Failed to update camera data:", response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error updating camera data:", error);
            });
        } else {
          console.error("Failed to add camera:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding camera:", error);
      });
    setShowModal(false);
    setShowConfirmModal(false);
  };

  const handleWatchCamera = () => {
    if (selectedCamera) {
      if (selectedCamera.two_lanes === "1") {
        navigate(`/liveinterfacetwo/${selectedCamera.cam_id}`, { state: { camera: selectedCamera } });
      } else {
        navigate(`/liveinterface/${selectedCamera.cam_id}`, { state: { camera: selectedCamera } });
      }
    }
    setShowCameraModal(false);
  };

  const handleEditCamera = () => {
    if (selectedCamera) {
      setEditedCamera({
        cam_name: selectedCamera.cam_name,
        cam_location: selectedCamera.cam_location,
        cam_url: selectedCamera.cam_url,
      });
      setShowEditModal(true);
    }
    setShowCameraModal(false);
  };

  const handleEditCameraChange = (e) => {
    setEditedCamera({ ...editedCamera, [e.target.name]: e.target.value });
  };

  const handleEditSubmit = () => {
    setShowEditConfirmModal(true);
  };

  const confirmEditCamera = async () => {
    try {
      const response = await axiosphp.post('/edit_traffic_camera.php', {
        cam_id: selectedCamera.cam_id,
        cam_name: editedCamera.cam_name,
        cam_location: editedCamera.cam_location,
        cam_url: editedCamera.cam_url,
      });

      if (response.data.success) {
        toast.success("Camera edited successfully", { autoClose: 3000 });
        fetchCameraData();
        
        await axios.post('/update_camera_data');

      } else {
        console.error('Failed to edit camera:', response.data.message);
      }
    } catch (error) {
      console.error('Error editing camera:', error);
    } finally {
      setShowEditConfirmModal(false);
      setShowEditModal(false);
    }
  };

  const handleDeleteCamera = async () => {
    if (selectedCamera) {
      try {
        const response = await axiosphp.post('/delete_traffic_camera.php', {
          cam_id: selectedCamera.cam_id,
        });

        if (response.data.success) {
          toast.success("Camera deleted successfully", { autoClose: 3000 });
          clearIntervalForCamera(selectedCamera.cam_id);
          setCameraData(prevData => prevData.filter(camera => camera.cam_id !== selectedCamera.cam_id));
          axios.post('/update_camera_data')
            .then((response) => {
              if (response.data.success) {
                console.log("Camera data updated successfully after deletion.");
                setCameraData((prevData) =>
                  prevData.filter((camera) => camera.cam_id !== selectedCamera.cam_id)
                );
              } else {
                console.error("Failed to update camera data:", response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error updating camera data after deletion:", error);
            });
        } else {
          console.error('Failed to delete camera:', response.data.message);
        }
      } catch (error) {
        console.error('Error deleting camera:', error);
      } finally {
        setShowDeleteConfirmModal(false);
        setShowCameraModal(false);
      }
    }
  };
  const handleShowDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(true);
  };

  return (
    <div>
      <ToastContainer  closeButton={false}/>
      <div id="mapstyle" className="mapstyle"></div>
      <Modal show={showModal} onHide={() => setShowModal(false)} onShow={initializeModalMap}>
        <Modal.Header closeButton>
          <Modal.Title>Add Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Camera Name</Form.Label>
              <Form.Control
                type="text"
                value={cameraName}
                onChange={(e) => setCameraName(e.target.value)}
                placeholder="Enter camera name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera Location</Form.Label>
              <Form.Control
                type="text"
                value={cameraLocation}
                onChange={(e) => setCameraLocation(e.target.value)}
                placeholder="Enter camera location"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera URL</Form.Label>
              <Form.Control
                type="text"
                value={cameraUrl}
                onChange={(e) => setCameraUrl(e.target.value)}
                placeholder="Enter camera URL"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Two Lanes"
                checked={isTwoLanes}
                onChange={(e) => setIsTwoLanes(e.target.checked)}
              />
            </Form.Group>
            <p>
              Latitude: {cameraPosition && cameraPosition.lat.toFixed(6)} <br />
              Longitude: {cameraPosition && cameraPosition.lng.toFixed(6)}
            </p>
            <Form.Group>
              <Form.Label>Map</Form.Label>
              <div id="modal-map" style={{ height: "145px", width: "100%" }}></div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddCamera}>
            Add Camera
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Add Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this camera?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmAddCamera}>
            Yes, Add Camera
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Camera Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>What would you like to do with this camera?</p>
          <Button variant="primary" onClick={handleWatchCamera}>
            Watch Camera
          </Button>
          <Button variant="secondary" onClick={handleEditCamera}>
            Edit Camera
          </Button>
          <Button variant="danger" onClick={handleShowDeleteConfirmModal}>
            Delete Camera
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCameraModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Camera Name</Form.Label>
              <Form.Control
                type="text"
                name="cam_name"
                value={editedCamera.cam_name || ''}
                onChange={handleEditCameraChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera Location</Form.Label>
              <Form.Control
                type="text"
                name="cam_location"
                value={editedCamera.cam_location || ''}
                onChange={handleEditCameraChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Camera URL</Form.Label>
              <Form.Control
                type="text"
                name="cam_url"
                value={editedCamera.cam_url || ''}
                onChange={handleEditCameraChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditConfirmModal} onHide={() => setShowEditConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save these changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmEditCamera}>
            Yes, Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this camera?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteCamera}>
            Yes, Delete Camera
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default MapComponent;