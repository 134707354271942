import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import drainage from "../images/drainage.png";
import entrance from "../images/Entrance.png";
import exit from "../images/Exit.png";
import noenter from "../images/noentry.png";
import building from "../images/building.png";
import roadcons from "../images/roadcons.png";
import fire from "../images/fire.png";
import vaccident from "../images/vaccident.png";
import flood from "../images/flood.png";
import landslide from "../images/landslide.png";
import axiosphp from "../api/axiosphp";
import { useFetchCongestionData } from "../hooks/useFetchCongestionData";

const markerIcons = {
  entrance: entrance,
  exit: exit,
  "no-entry": noenter,
  drainage: drainage,
  building: building,
  road: roadcons,
  fire: fire,
  flood: flood,
  landslide: landslide,
  "vehicular-accidents": vaccident,
  // Added from copy.js
  "Entrance Only": entrance,
  "Exit Only": exit,
  "No Entry": noenter,
  "Drainage": drainage,
  "Building": building,
  "Road": roadcons,
  "Fire": fire,
  "Flood": flood,
  "Landslide": landslide,
  "Vehicular Accidents": vaccident,
};

// Only used for shapes (polygon, polyline, rectangle, circle)
const shapeColorOptions = {
  green: "#00FF00",
  red: "#FF0000",
  yellow: "#FFFF00",
  blue: "#0000FF",
};

// Congestion color reference for camera updates
const tcongestionColor = {
  Gridlock: "#AB0003",
  Heavy: "#FF1519",
  Moderate: "#FFA800",
  Light: "#FFE500",
  Freeflow: "#05FF00",
  null: "white",
};

function MapComponentDraw({ markerType, drawColor, eraseMode, overlaysRef, initialDrawings }) {
  const mapRef = useRef(null);
  const drawingManagerRef = useRef(null);
  const initialDrawingsLoadedRef = useRef(false);

  // Store cameras fetched from backend
  const [cameraData, setCameraData] = useState([]);

  // Track camera overlays separately
  const cameraOverlaysRef = useRef({});
  // Track two-lane polygons
  const lanePolygonsRef = useRef({});

  // Normalize text for congestion
  const normalize = (level) => {
    if (!level) return "Freeflow";
    return level.charAt(0).toUpperCase() + level.slice(1).toLowerCase();
  };

  // Update single-lane or two-lane marker icons
  const updateMarkerIcon = (marker, tcongestion, camera) => {
    const isTwoLanes = camera.two_lanes === 1 || camera.two_lanes === "1";
    if (isTwoLanes) {
      const lane1 = normalize(tcongestion?.lane1);
      const lane2 = normalize(tcongestion?.lane2);
      const color1 = tcongestionColor[lane1] || "black";
      const color2 = tcongestionColor[lane2] || "black";

      const canvas = document.createElement("canvas");
      canvas.width = 30;
      canvas.height = 30;
      const ctx = canvas.getContext("2d");

      // Left half
      ctx.beginPath();
      ctx.arc(15, 15, 12, 0.5 * Math.PI, 1.5 * Math.PI);
      ctx.closePath();
      ctx.fillStyle = color1;
      ctx.fill();

      // Right half
      ctx.beginPath();
      ctx.arc(15, 15, 12, 1.5 * Math.PI, 0.5 * Math.PI);
      ctx.closePath();
      ctx.fillStyle = color2;
      ctx.fill();

      // Border
      ctx.beginPath();
      ctx.arc(15, 15, 12, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#000";
      ctx.stroke();

      marker.setIcon({
        url: canvas.toDataURL(),
        scaledSize: new window.google.maps.Size(16, 16),
      });
    } else {
      const congestionLevel = normalize(tcongestion?.lane1);
      const fillColor = tcongestionColor[congestionLevel] || "black";
      marker.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: fillColor,
        fillOpacity: 1,
        scale: 6.5,
        strokeColor: "#000",
        strokeWeight: 1.6,
      });
    }
  };


  const reroutingColor = "#0066FF"; // Blue color for rerouting
  const directionsService = new window.google.maps.DirectionsService();
  const directionsRenderer = new window.google.maps.DirectionsRenderer({
    suppressMarkers: true,
    preserveViewport: true,
    polylineOptions: {
      strokeColor: reroutingColor,
      strokeWeight: 3,
      strokeOpacity: 0,
      icons: [{
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          scale: 3
        },
        offset: '0',
        repeat: '20px'
      }]
    }
  });

  // Add these to your component state/refs
  const [activeReroutes, setActiveReroutes] = useState({});
  const reroutingRef = useRef({});

  // Add these helper functions
  const getCongestionLevel = (level) => {
    const levels = {
      "Freeflow": 1,
      "Light": 2,
      "Moderate": 3,
      "Heavy": 4,
      "Gridlock": 5
    };
    return levels[normalize(level)] || 1;
  };

  const findLowestCongestionCamera = (cameras, laneNumber, excludeId) => {
    let lowestLevel = Infinity;
    let bestCamera = null;
  
    cameras.forEach(camera => {
      if (camera.cam_id === excludeId) return;
  
      const isTwoLanes = camera.two_lanes === 1 || camera.two_lanes === "1";
      if (!isTwoLanes) return;
  
      // Get source camera lane direction
      const sourceLane = lanePolygonsRef.current[excludeId][`lane${laneNumber}`];
      const sourceDirection = sourceLane.metadata.direction;
  
      // Check if camera has compatible lane direction
      const hasCompatibleLane = Object.values(lanePolygonsRef.current[camera.cam_id])
        .some(lane => lane.metadata.direction === sourceDirection);
  
      if (!hasCompatibleLane) return;
  
      // Find congestion level for compatible lane
      const compatibleLane = findCompatibleLane(
        {cam_id: excludeId, laneNumber},
        camera
      );
      
      const laneCongestion = compatibleLane === 1 ?
        camera.currentCongestion?.lane1 :
        camera.currentCongestion?.lane2;
  
      const congestionLevel = getCongestionLevel(laneCongestion);
  
      if (congestionLevel < 4 && congestionLevel < lowestLevel) {
        lowestLevel = congestionLevel;
        bestCamera = camera;
      }
    });
  
    return bestCamera;
  };

  const congestionHistoryRef = useRef({}); // Store recent congestion history
  const HISTORY_WINDOW = 5; // Number of recent readings to keep
    const SUDDEN_CHANGE_THRESHOLD = 2; // Minimum level jump to consider as sudden
    const MIN_GRIDLOCK_COUNT = 3; // Minimum consecutive readings showing high congestion
  
  //newADded
  const detectTrafficAnomaly = (camera, laneNumber, currentLevel) => {
    const cameraKey = `${camera.cam_id}_${laneNumber}`;

    if (!congestionHistoryRef.current[cameraKey]) {
      congestionHistoryRef.current[cameraKey] = {
        levels: [],
        lastUpdateTime: null
      };
    }

    const history = congestionHistoryRef.current[cameraKey];
    const currentTime = new Date();

    history.levels.push(currentLevel);
    history.lastUpdateTime = currentTime;

    if (history.levels.length > HISTORY_WINDOW) {
      history.levels.shift();
    }

    if (history.levels.length < 3) return false;

    const recentLevels = history.levels.slice(-3);
    const isIncreasing = recentLevels.every((level, i) =>
      i === 0 || level >= recentLevels[i - 1]
    );

    const suddenChange = isIncreasing &&
      (currentLevel - history.levels[0] >= SUDDEN_CHANGE_THRESHOLD);

    const highCongestionCount = recentLevels.filter(
      level => level >= 4
    ).length;

    return suddenChange && highCongestionCount >= MIN_GRIDLOCK_COUNT;
};

const handleRerouting = (camera, tcongestion) => {
    const isTwoLanes = camera.two_lanes === 1 || camera.two_lanes === "1";
    if (!isTwoLanes) return;

    const lane1Level = getCongestionLevel(tcongestion?.lane1);
    const lane2Level = getCongestionLevel(tcongestion?.lane2);

    const lane1Anomaly = detectTrafficAnomaly(camera, 1, lane1Level);
    const lane2Anomaly = detectTrafficAnomaly(camera, 2, lane2Level);

    // Handle Lane 1
    const routeKey1 = `${camera.cam_id}_1`;
    if (lane1Level >= 4) {
      const bestAlternative = findLowestCongestionCamera(cameraData, 1, camera.cam_id);

      if (bestAlternative) {
        const currentRoute = reroutingRef.current[routeKey1];
        if (currentRoute) {
          const currentAltCongestion = currentRoute.toCamera.currentCongestion?.lane1;
          const currentAltLevel = getCongestionLevel(currentAltCongestion);

          const newAltCongestion = bestAlternative.currentCongestion?.lane1;
          const newAltLevel = getCongestionLevel(newAltCongestion);

          if (newAltLevel < currentAltLevel) {
            clearReroute(routeKey1);
            createReroute(camera, bestAlternative, 1);
          }
        } else {
          createReroute(camera, bestAlternative, 1);
        }
      }
    } else if (reroutingRef.current[routeKey1]) {
      clearReroute(routeKey1);
    }

    // Handle Lane 2
    const routeKey2 = `${camera.cam_id}_2`;
    if (lane2Level >= 4) {
      const bestAlternative = findLowestCongestionCamera(cameraData, 2, camera.cam_id);

      if (bestAlternative) {
        const currentRoute = reroutingRef.current[routeKey2];
        if (currentRoute) {
          const currentAltCongestion = currentRoute.toCamera.currentCongestion?.lane2;
          const currentAltLevel = getCongestionLevel(currentAltCongestion);

          const newAltCongestion = bestAlternative.currentCongestion?.lane2;
          const newAltLevel = getCongestionLevel(newAltCongestion);

          if (newAltLevel < currentAltLevel) {
            clearReroute(routeKey2);
            createReroute(camera, bestAlternative, 2);
          }
        } else {
          createReroute(camera, bestAlternative, 2);
        }
      }
    } else if (reroutingRef.current[routeKey2]) {
      clearReroute(routeKey2);
    }
};
  
    const isRerouteSource = (cameraId, laneNumber) => {
      return Object.values(reroutingRef.current).some(
        reroute => reroute.fromCamera.cam_id === cameraId && reroute.laneNumber === laneNumber
      );
    };
  
    const findCompatibleLane = (fromLane, toCamera) => {
      // Get source camera metadata
      const fromPolygon = lanePolygonsRef.current[fromLane.cam_id][`lane${fromLane.laneNumber}`];
      const fromDirection = fromPolygon.metadata.direction;
      const fromPos = {
          lat: fromPolygon.metadata.position[0].lat,
          lng: fromPolygon.metadata.position[0].lng
      };
  
      // Get target position
      const toPos = {
          lat: parseFloat(toCamera.cam_latitude),
          lng: parseFloat(toCamera.cam_longitude)
      };
  
      // Calculate angle between cameras
      const angle = Math.atan2(toPos.lat - fromPos.lat, toPos.lng - fromPos.lng) * (180 / Math.PI);
      const normalizedAngle = (angle + 360) % 360;
  
      // Convert direction to angle
      const directionMap = { N: 0, E: 90, S: 180, W: 270 };
      const baseAngle = directionMap[fromDirection] || 0;
      const diff = (normalizedAngle - baseAngle + 360) % 360;
  
      // Lane assignment based on direction
      if (diff < 45 || diff >= 315) {
          return 2;  // Forward -> lane 2 (right lane)
      } else if (diff >= 45 && diff < 135) {
          return 1;  // Right turn -> lane 1 (left lane)
      } else if (diff >= 135 && diff < 225) {
          return fromLane.laneNumber;  // Behind -> maintain current lane
      } else {
          return fromLane.laneNumber;  // Behind -> maintain current lane
      }
  };
    // Helper function to determine if a lane is the right lane
    const isRightLane = (pathCoords) => {
      // Calculate average longitude to determine rightmost position
      const avgLng = pathCoords.reduce((sum, coord) => {
        return sum + (typeof coord.lng === 'number' ? coord.lng : coord.x);
      }, 0) / pathCoords.length;
  
      return avgLng > 0; // True if right lane, false if left lane
    };
  

  const createReroute = (fromCamera, toCamera, laneNumber) => {
    const routeKey = `${fromCamera.cam_id}_${laneNumber}`;
  
    if (reroutingRef.current[routeKey]) {
      clearReroute(routeKey);
    }
  
    const targetLane = findCompatibleLane(
      {cam_id: fromCamera.cam_id, laneNumber}, 
      toCamera
    );
  
    const request = {
      origin: {
        lat: parseFloat(fromCamera.cam_latitude),
        lng: parseFloat(fromCamera.cam_longitude)
      },
      destination: {
        lat: parseFloat(toCamera.cam_latitude),
        lng: parseFloat(toCamera.cam_longitude) 
      },
      travelMode: 'DRIVING'
    };
  
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        const newRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          preserveViewport: true,
          polylineOptions: {
            strokeColor: reroutingColor,
            strokeWeight: 2,
            strokeOpacity: 0,
            zIndex: 1000,
            icons: [{
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: reroutingColor,
                fillOpacity: 1,
                strokeColor: reroutingColor,
                strokeWeight: 2,
                scale: 4,
              },
              offset: '0',
              repeat: '60px'
            }]
          },
          map: mapRef.current
        });
  
        newRenderer.setDirections(result);
  
        const fromPolygons = lanePolygonsRef.current[fromCamera.cam_id];
        const toPolygons = lanePolygonsRef.current[toCamera.cam_id];
  
        if (fromPolygons && toPolygons) {
          const sourcePolygon = fromPolygons[`lane${laneNumber}`];
          const targetPolygon = toPolygons[`lane${targetLane}`];
  
          // Create copies of the polygons for overlaysRef
          if (sourcePolygon) {
            const sourceCopy = new window.google.maps.Polygon({
              paths: sourcePolygon.getPath(),
              map: mapRef.current,
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              strokeWeight: 0,
              fillOpacity: 0.3
            });
  
            sourceCopy.metadata = {
              type: 'polygon',
              color: reroutingColor,
              isReroute: true,
              reroute: {
                type: 'source',
                camId: fromCamera.cam_id,
                lane: laneNumber,
                routeKey
              }
            };
  
            overlaysRef.current.push(sourceCopy);
  
            // Update original polygon
            sourcePolygon.setOptions({
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              fillOpacity: 0.3
            });
          }
  
          if (targetPolygon) {
            const targetCopy = new window.google.maps.Polygon({
              paths: targetPolygon.getPath(),
              map: mapRef.current,
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              strokeWeight: 0,
              fillOpacity: 0.3
            });
  
            targetCopy.metadata = {
              type: 'polygon',
              color: reroutingColor,
              isReroute: true,
              reroute: {
                type: 'target',
                camId: toCamera.cam_id,
                lane: targetLane,
                routeKey
              }
            };
  
            overlaysRef.current.push(targetCopy);
  
            // Update original polygon
            targetPolygon.setOptions({
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              fillOpacity: 0.3
            });
          }
        }
  
        reroutingRef.current[routeKey] = {
          renderer: newRenderer,
          fromCamera,
          toCamera,
          laneNumber,
          targetLane,
          sourcePolygon: fromPolygons[`lane${laneNumber}`],
          targetPolygon: toPolygons[`lane${targetLane}`]
        };
  
        setActiveReroutes(prev => ({
          ...prev,
          [routeKey]: {
            fromCamera,
            toCamera,
            laneNumber,
            targetLane
          }
        }));
      }
    });
  };

  const isRerouteDestination = (cameraId, laneNumber) => {
    return Object.values(reroutingRef.current).some(
      reroute => reroute.toCamera.cam_id === cameraId && reroute.targetLane === laneNumber
    );
  };

  const clearReroute = (routeKey) => {
    if (reroutingRef.current[routeKey]) {
      const reroute = reroutingRef.current[routeKey];
  
      // Remove direction renderer
      reroute.renderer.setMap(null);
  
      // Remove copies from overlaysRef
      overlaysRef.current = overlaysRef.current.filter(overlay => {
        if (overlay.metadata?.isReroute && overlay.metadata.reroute.routeKey === routeKey) {
          overlay.setMap(null);
          return false;
        }
        return true;
      });
  
      // Restore original polygon colors
      if (reroute.sourcePolygon) {
        const congestion = reroute.fromCamera.currentCongestion?.[`lane${reroute.laneNumber}`];
        const color = tcongestionColor[normalize(congestion)] || "black";
        reroute.sourcePolygon.setOptions({
          fillColor: color,
          strokeColor: "#000",
          fillOpacity: 0.8
        });
      }
  
      if (reroute.targetPolygon) {
        const congestion = reroute.toCamera.currentCongestion?.[`lane${reroute.targetLane}`];
        const color = tcongestionColor[normalize(congestion)] || "black";
        reroute.targetPolygon.setOptions({
          fillColor: color,
          strokeColor: "#000",
          fillOpacity: 0.8
        });
      }
  
      delete reroutingRef.current[routeKey];
      setActiveReroutes(prev => {
        const newReroutes = { ...prev };
        delete newReroutes[routeKey];
        return newReroutes;
      });
    }
  };

  // Update polygons for single-lane or two-lane cameras
  const updatePolygonColor = (polygon, tcongestion, camera) => {
    camera.currentCongestion = tcongestion;
    const isTwoLanes = camera.two_lanes === 1 || camera.two_lanes === "1";
    const lane1 = normalize(tcongestion?.lane1);
    const lane2 = normalize(tcongestion?.lane2);
    const color1 = tcongestionColor[lane1] || "black";
    const color2 = tcongestionColor[lane2] || "black";

  if (isTwoLanes) {
    if (lanePolygonsRef.current[camera.cam_id]) {
      const { lane1: lane1Polygon, lane2: lane2Polygon } = 
        lanePolygonsRef.current[camera.cam_id];


        if (lane1Polygon) {
          if (isRerouteSource(camera.cam_id, 1)) {
            lane1Polygon.setOptions({
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              fillOpacity: 0.3
            });
          } else if (isRerouteDestination(camera.cam_id, 1)) {
            // Add this check for destination
            lane1Polygon.setOptions({
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              fillOpacity: 0.3
            });
          } else {
            lane1Polygon.setOptions({
              fillColor: color1,
              strokeColor: "#000",
              fillOpacity: 0.8
            });
          }
        }

        if (lane2Polygon) {
          if (isRerouteSource(camera.cam_id, 2)) {
            lane2Polygon.setOptions({
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              fillOpacity: 0.3
            });
          } else if (isRerouteDestination(camera.cam_id, 2)) {
            // Add this check for destination
            lane2Polygon.setOptions({
              fillColor: reroutingColor,
              strokeColor: reroutingColor,
              fillOpacity: 0.3
            });
          } else {
            lane2Polygon.setOptions({
              fillColor: color2,
              strokeColor: "#000",
              fillOpacity: 0.8
            });
          }
        }

      } else if (polygon.lane === "lane1" || polygon.lane === "lane2") {
        // If polygon is already designated as lane1 or lane2
        const laneNumber = parseInt(polygon.lane.slice(-1));
        if (!isRerouteDestination(camera.cam_id, laneNumber)) {
          polygon.setOptions({
            fillColor: polygon.lane === "lane1" ? color1 : color2,
            strokeColor: "#000",
            fillOpacity: 0.8
          });
        }
      } else {
        // Split polygon if possible
        const streetCoords = JSON.parse(camera.streetlocation);
        if (Array.isArray(streetCoords) && streetCoords.length === 2) {
          const theMap = polygon.getMap();

          // Lane1 polygon
          const lane1Polygon = new window.google.maps.Polygon({
            paths: streetCoords[0],
            map: theMap,
            strokeColor: "#000",
            strokeWeight: 0,
            fillColor: isRerouteDestination(camera.cam_id, 1) ? reroutingColor : color1,
            fillOpacity: 0.8,
            // Add metadata for lane direction
            metadata: {
              lane: "lane1",
              direction: camera.direction || "N", // Add direction property from camera data
              position: streetCoords[0] // Store original coordinates
            }
          });

          lane1Polygon.lane = "lane1";

          // Lane2 polygon
          const lane2Polygon = new window.google.maps.Polygon({
            paths: streetCoords[1],
            map: theMap,
            strokeColor: "#000",
            strokeWeight: 0,
            fillColor: isRerouteDestination(camera.cam_id, 2) ? reroutingColor : color2,
            fillOpacity: 0.8,
            // Add metadata for lane direction
            metadata: {
              lane: "lane2",
              direction: camera.direction || "S", // Add direction property from camera data  
              position: streetCoords[1] // Store original coordinates
            }
          });
          lane2Polygon.lane = "lane2";

          lanePolygonsRef.current[camera.cam_id] = {
            lane1: lane1Polygon,
            lane2: lane2Polygon,
          };

          // Remove original
          polygon.setMap(null);
        } else {
          // Single polygon fallback
          if (!isRerouteDestination(camera.cam_id, 1)) {
            polygon.setOptions({
              fillColor: color1,
              fillOpacity: 0.8,
              strokeColor: "#000",
              strokeWeight: 0,
            });
          }
        }
      }
    } else {
      // Single-lane fallback
      if (!isRerouteDestination(camera.cam_id, 1)) {
        polygon.setOptions({
          fillColor: color1,
          fillOpacity: 0.8,
          strokeColor: "#000",
          strokeWeight: 0,
        });
      }
    }

    handleRerouting(camera, tcongestion);
  };

  // Fetch congestion in the background for cameras
  const { continuouslyFetchCongestionData } = useFetchCongestionData(
    cameraData,
    updateMarkerIcon,
    updatePolygonColor
  );


  // Pull camera data
  const fetchCameraData = () => {
    axiosphp.get("/fetch_traffic_cameras.php")
      .then((response) => {
        setCameraData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching camera data:", error);
      });
  };

  useEffect(() => {
    fetchCameraData();
  }, []);

  // Erase mode logic
  const addEraseListener = (overlay) => {
    const listenerHandle = window.google.maps.event.addListener(overlay, "click", function () {
      overlay.setMap(null);
      overlaysRef.current = overlaysRef.current.filter((item) => item !== overlay);
    });
    overlay.listenerHandle = listenerHandle;
  };

  // Configure newly drawn markers by user
  const configureMarker = (marker) => {
    const iconUrl =
      typeof markerType === 'string' && markerIcons[markerType.toLowerCase()]
        ? markerIcons[markerType.toLowerCase()]
        : markerIcons[markerType] || null;
    const labelMapping = {
      entrance: "Entrance Only",
      exit: "Exit Only",
      "no-entry": "No Entry",
      drainage: "Drainage",
      building: "Building",
      road: "Road",
      fire: "Fire",
      flood: "Flood",
      landslide: "Landslide",
      "vehicular-accidents": "Vehicular Accidents",
      "Entrance Only": "Entrance Only",
      "Exit Only": "Exit Only",
      "No Entry": "No Entry",
      "Drainage": "Drainage",
      "Building": "Building",
      "Road": "Road",
      "Fire": "Fire",
      "Flood": "Flood",
      "Landslide": "Landslide",
      "Vehicular Accidents": "Vehicular Accidents",
    };
    if (iconUrl) {
      marker.setIcon({
        url: iconUrl,
        scaledSize: new window.google.maps.Size(50, 30),
      });
      marker.setLabel(
        typeof markerType === 'string' ? (labelMapping[markerType.toLowerCase()] || labelMapping[markerType] || "") : ""
      );
    } else {
      // If no iconUrl, default to simple circle
      marker.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "white",
        fillOpacity: 1,
        scale: 6.5,
        strokeColor: "#000",
        strokeWeight: 1.6,
      });
      marker.setLabel("");
    }
  };

  // Configure circle style (not cameras)
  const configureCircle = (circle) => {
    const selectedColor = shapeColorOptions[drawColor] || "#0000FF";
    circle.setOptions({
      strokeColor: selectedColor,
      fillColor: selectedColor,
      fillOpacity: 0.35,
      strokeWeight: 3,
    });
  };

  // Configure polygon style (not cameras)
  const configurePolygon = (polygon) => {
    const selectedColor = shapeColorOptions[drawColor] || "#0000FF";
    polygon.setOptions({
      strokeColor: selectedColor,
      fillColor: selectedColor,
      fillOpacity: 0.35,
      strokeWeight: 3,
    });
  };

  // Configure polyline style (not cameras)
  const configurePolyline = (polyline) => {
    const selectedColor = shapeColorOptions[drawColor] || "#0000FF";
    polyline.setOptions({
      strokeColor: selectedColor,
      strokeWeight: 4,
    });
  };

  // Configure rectangle style (not cameras)
  const configureRectangle = (rectangle) => {
    const selectedColor = shapeColorOptions[drawColor] || "#0000FF";
    rectangle.setOptions({
      strokeColor: selectedColor,
      fillColor: selectedColor,
      fillOpacity: 0.35,
      strokeWeight: 3,
    });
  };

  // Update only marker icon and label when markerType changes
  useEffect(() => {
    if (drawingManagerRef.current && window.google?.maps) {
      const iconUrl =
        typeof markerType === 'string' && markerIcons[markerType.toLowerCase()]
          ? markerIcons[markerType.toLowerCase()]
          : markerIcons[markerType] || null;
      const labelMapping = {
        entrance: "Entrance Only",
        exit: "Exit Only",
        "no-entry": "No Entry",
        drainage: "Drainage",
        building: "Building",
        road: "Road",
        fire: "Fire",
        flood: "Flood",
        landslide: "Landslide",
        "vehicular-accidents": "Vehicular Accidents",
        "Entrance Only": "Entrance Only",
        "Exit Only": "Exit Only",
        "No Entry": "No Entry",
        "Drainage": "Drainage",
        "Building": "Building",
        "Road": "Road",
        "Fire": "Fire",
        "Flood": "Flood",
        "Landslide": "Landslide",
        "Vehicular Accidents": "Vehicular Accidents",
      };
      drawingManagerRef.current.setOptions({
        markerOptions: {
          icon: iconUrl
            ? {
              url: iconUrl,
              scaledSize: new window.google.maps.Size(50, 30),
            }
            : {
              path: window.google.maps.SymbolPath.CIRCLE,
              fillColor: "white",
              fillOpacity: 1,
              scale: 6.5,
              strokeColor: "#000",
              strokeWeight: 1.6,
            },
          label: typeof markerType === 'string' ? (labelMapping[markerType.toLowerCase()] || labelMapping[markerType] || "") : "",
          draggable: true,
        },
      });
      drawingManagerRef.current.setDrawingMode(null);
    }
  }, [markerType]);

  // Update shape drawing colors when drawColor changes
  useEffect(() => {
    if (drawingManagerRef.current && window.google?.maps) {
      const selectedColor = shapeColorOptions[drawColor] || "#0000FF";
      drawingManagerRef.current.setOptions({
        polylineOptions: {
          strokeColor: selectedColor,
          strokeWeight: 4,
        },
        polygonOptions: {
          strokeColor: selectedColor,
          fillColor: selectedColor,
          fillOpacity: 0.35,
          strokeWeight: 3,
        },
        rectangleOptions: {
          strokeColor: selectedColor,
          fillColor: selectedColor,
          fillOpacity: 0.35,
          strokeWeight: 3,
        },
        circleOptions: {
          strokeColor: selectedColor,
          fillColor: selectedColor,
          fillOpacity: 0.35,
          strokeWeight: 3,
        },
      });
      drawingManagerRef.current.setDrawingMode(null);
    }
  }, [drawColor]);

  // Main map load function
  const loadMap = () => {
    if (!window.google || !window.google.maps || !window.google.maps.Marker) {
      console.error("Google Maps JavaScript API not loaded.");
      return;
    }
    if (!mapRef.current) {
      const styles = [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ];
      const map = new window.google.maps.Map(document.getElementById("mapstyle"), {
        center: { lat: 11.2416, lng: 125.0028 },
        zoom: 13,
        styles: styles,
      });
      mapRef.current = map;
    }

    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }
    const selectedColor = shapeColorOptions[drawColor] || "#0000FF";

    // Setup DrawingManager with separate marker icon logic
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["marker", "circle", "polygon", "polyline", "rectangle"],
      },
      markerOptions: {
        draggable: true,
        icon:
          typeof markerType === 'string' && markerIcons[markerType.toLowerCase()]
            ? {
              url: markerIcons[markerType.toLowerCase()],
              scaledSize: new window.google.maps.Size(50, 30),
            }
            : markerIcons[markerType]
              ? {
                url: markerIcons[markerType],
                scaledSize: new window.google.maps.Size(50, 30),
              }
              : {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "white",
                fillOpacity: 1,
                scale: 6.5,
                strokeColor: "#000",
                strokeWeight: 1.6,
              },
        label: markerType
          ? typeof markerType === 'string'
            ? (markerType)
            : ""
          : "",
      },
      polylineOptions: { strokeColor: selectedColor, strokeWeight: 4 },
      polygonOptions: {
        strokeColor: selectedColor,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        strokeWeight: 3,
      },
      rectangleOptions: {
        strokeColor: selectedColor,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        strokeWeight: 3,
      },
      circleOptions: {
        strokeColor: selectedColor,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        strokeWeight: 3,
      },
    });
    drawingManager.setMap(mapRef.current);
    drawingManagerRef.current = drawingManager;

    // Listen for completed overlays
    window.google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
      const overlay = event.overlay;
      overlay.metadata = { type: event.type, color: selectedColor };

      // Configure user-drawn overlays
      switch (event.type) {
        case window.google.maps.drawing.OverlayType.MARKER:
          configureMarker(overlay);
          break;
        case window.google.maps.drawing.OverlayType.CIRCLE:
          configureCircle(overlay);
          break;
        case window.google.maps.drawing.OverlayType.POLYGON:
          configurePolygon(overlay);
          break;
        case window.google.maps.drawing.OverlayType.POLYLINE:
          configurePolyline(overlay);
          break;
        case window.google.maps.drawing.OverlayType.RECTANGLE:
          configureRectangle(overlay);
          break;
        default:
          break;
      }

      overlaysRef.current.push(overlay);
      if (eraseMode) {
        addEraseListener(overlay);
      }
    });

    // Helper to clear current overlays
    window.clearMap = () => {
      overlaysRef.current.forEach((overlay) => {
        overlay.setMap(null);
      });
      overlaysRef.current = [];
    };

    // Display camera markers & polygons without user-chosen icon
    cameraData.forEach((camera) => {
      if (!camera || !camera.cam_latitude || !camera.cam_longitude) return;
      const camId = camera.cam_id;
      if (!camId) return;
      if (cameraOverlaysRef.current[camId]) return;

      // Always default camera marker to a white circle initially
      const marker = new window.google.maps.Marker({
        position: {
          lat: parseFloat(camera.cam_latitude),
          lng: parseFloat(camera.cam_longitude),
        },
        map: mapRef.current,
        title: camera.cam_name,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: "white",
          fillOpacity: 1,
          scale: 6.5,
          strokeColor: "#000",
          strokeWeight: 1.6,
        },
      });

      let polygon = null;
      if (camera.streetlocation) {
        const streetCoords = JSON.parse(camera.streetlocation);
        polygon = new window.google.maps.Polygon({
          paths: streetCoords,
          map: mapRef.current,
          strokeColor: "#000",
          strokeWeight: 1.6,
          fillOpacity: 0.35,
          geodesic: true,
        });
      }

      cameraOverlaysRef.current[camId] = { marker, polygon };
      // Setup congestion auto-updates
      continuouslyFetchCongestionData(camera, marker, polygon);
    });
  };

  // Load or reload map
  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.drawing) {
      console.error("Google Maps or Drawing library not loaded.");
      return;
    }
    loadMap();

    // Re-play initialDrawings once
    if (!initialDrawingsLoadedRef.current && initialDrawings && initialDrawings.length > 0) {
      initialDrawings.forEach((data) => {
        let overlay;
        switch (data.type) {
          case window.google.maps.drawing.OverlayType.MARKER:
            overlay = new window.google.maps.Marker({
              position: data.position,
              map: mapRef.current,
              icon: {
                url: markerIcons[data.name] || null,
                scaledSize: new window.google.maps.Size(50, 30),
              },
              label: typeof data.name === 'string' ? data.name : "",
              draggable: true,
            });
            break;
          case window.google.maps.drawing.OverlayType.CIRCLE:
            overlay = new window.google.maps.Circle({
              center: data.position,
              radius: data.size,
              map: mapRef.current,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYGON:
            overlay = new window.google.maps.Polygon({
              paths: data.position,
              map: mapRef.current,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          case window.google.maps.drawing.OverlayType.POLYLINE:
            overlay = new window.google.maps.Polyline({
              path: data.position,
              map: mapRef.current,
              strokeColor: data.color,
              strokeWeight: 4,
            });
            break;
          case window.google.maps.drawing.OverlayType.RECTANGLE:
            overlay = new window.google.maps.Rectangle({
              bounds: data.position,
              map: mapRef.current,
              strokeColor: data.color,
              fillColor: data.color,
              fillOpacity: 0.35,
              strokeWeight: 3,
            });
            break;
          default:
            console.warn(`Unsupported overlay type: ${data.type}`);
            return;
        }
        overlay.metadata = { type: data.type, color: data.color };
        overlaysRef.current.push(overlay);
        if (eraseMode) {
          addEraseListener(overlay);
        }
      });
      initialDrawingsLoadedRef.current = true;
    }
  }, [markerType, drawColor, eraseMode, initialDrawings]);

  // Toggle erase listeners
  useEffect(() => {
    overlaysRef.current.forEach((overlay) => {
      if (eraseMode) {
        if (!overlay.listenerHandle) {
          addEraseListener(overlay);
        }
      } else {
        if (overlay.listenerHandle) {
          window.google.maps.event.removeListener(overlay.listenerHandle);
          delete overlay.listenerHandle;
        }
      }
    });
    return () => {
      overlaysRef.current.forEach((overlay) => {
        if (overlay.listenerHandle) {
          window.google.maps.event.removeListener(overlay.listenerHandle);
          delete overlay.listenerHandle;
        }
      });
    };
  }, [eraseMode]);

  // Save overlays function to ensure 'name' is a string
  const saveOverlays = () => {
    const savedData = overlaysRef.current.map((overlay) => {
      let name = null;
      if (overlay.getLabel) {
        name = overlay.getLabel() || null;
      }
      return {
        name,
        size: overlay.getRadius ? overlay.getRadius() : null,
        type: overlay.constructor.name.toLowerCase(),
        color: overlay.metadata?.color || "#0000FF",
        position: overlay.getPosition
          ? overlay.getPosition().toJSON()
          : overlay.getPath
            ? overlay.getPath().getArray().map((coord) => coord.toJSON())
            : overlay.getBounds
              ? overlay.getBounds().toJSON()
              : null,
      };
    });
    // Now save `savedData` as needed, e.g., send to backend or store in state
    console.log("Saved Overlays:", savedData);
  };

  return <div id="mapstyle" className="mapstyle"></div>;
}

export default MapComponentDraw;